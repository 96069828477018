.parentContainerUSP {
    background-color: #ffffff;
    font-family: var(--font-primary);
}

.headingParent {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.heading {
    font-size: clamp(18px, 3.12vw, 60px);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    max-width: 65vw;
    text-align: center;
    color: #35255a;
}

.itemContainer {
    padding: clamp(0px, 3.2vw, 65px) 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.imgContainer {
    position: relative;
    height: clamp(42px, 6.7vw, 130px);
    width: 100%;
}

.content {
    padding: clamp(10px, 1.5vw, 30px) 0px;
    color: rgba(51, 51, 51, 0.85);
}

.itemNumber {
    font-size: clamp(18px, 2.9vw, 56px);
    font-weight: 900;
    letter-spacing: -0.9px;
    margin: 0;
}

.itemQuantity {
    font-size: clamp(12px, 1.6vw, 32px);
    font-weight: 600;
    line-height: 0.88;
    letter-spacing: -0.51px;
}

@media screen and (max-width: 767px) {
    .heading {
        line-height: 0.6;
        letter-spacing: 1px;
    }

    .itemNumber {
        font-style: italic;
        font-size:80%;
    }

    .itemQuantity {
        line-height: 0.68;
        letter-spacing: normal;
    }
}